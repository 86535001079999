import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import Profile from './components/users/Profile';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//MUI
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import ResponsiveAppBar from './components/global/AppBar';
import Broadcast from './components/broadcast/Broadcast';
import Directory from './components/users/Directory';
import MediaManager from './components/media-manager/MediaManager';
import Cases from './components/sheltracks/legal/Cases';
import CaseDetail from './components/sheltracks/legal/CaseDetail';
import MembershipTiers from './components/memberships/MembershipTiers';
import Dashboard from './components/dashboard/Dashboard';
import './App.scss';
import { DynamoDB } from 'aws-sdk';
import { CircularProgress } from '@mui/material';

const dynamoDB = new DynamoDB.DocumentClient({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});


Amplify.configure(awsExports);

export default function App() {
  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];
  localStorage.setItem("networkId", hostname);
  const [network, setNetwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNetwork = async () => {
        try {
            const queryParams = {
                TableName: 'networks',
                KeyConditionExpression: 'id = :id',
                ExpressionAttributeValues: {
                    ':id': hostname,
                },
            };

            const result = await dynamoDB.query(queryParams).promise();
            if (result.Items.length === 0) {
                setError('Please use the correct URL.');
            } else {
                setNetwork(result.Items[0]);
                console.log("network items", result.Items[0]);
            }
        } catch (err) {
            setError(`Error fetching profile: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    fetchNetwork();
}, [hostname]);

if (loading) {
    return <CircularProgress />;
}

if (error) {
    return <p>{error}</p>;
}

  return (
    <Authenticator variation="modal">
      <Router>
        <ResponsiveAppBar/>
        <CssBaseline />
        <Container maxWidth="100%" style={{backgroundColor: '#000', color:'#fff', height: '100%', paddingBottom: '48px'}}>
          <Routes>
            <Route exact path="/" element={<Dashboard/>}/>
            <Route path="/profile/:userId?" element={<Profile />} />
            <Route exact path="genres" element={<Dashboard/>}/>
            <Route exact path="profile" element={<Profile />}/>
            <Route exact path="broadcast" element={<Broadcast />}/>
            <Route exact path="/broadcast/:category_id" element={<Broadcast />}/>
            <Route exact path="directory" element={<Directory />}/>
            <Route exacr path="/directory/:category_id" element={<Directory/>}/>
            <Route exact path="media-manager" element={<MediaManager />}/>
            <Route exact path="/media-manager/:user_role?" element={<MediaManager />}/>
            <Route path="/memberships/membership-tiers" element={<MembershipTiers/>}/>
            {/* <Route exact path="cases" element={<Cases/>}/> */}
          </Routes>
        </Container>
      </Router>
    </Authenticator>
  );
}